// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Doughnut, Line } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, { Component } from "react";

import {
  chartOptions,
  parseOptions
} from "variables/charts.js";

import {
  p,
  getData,
  getTwoTimeObj_ForHour,
  getHoursArray_ForHour,
  getTwoTimeObj_ForDay,
  getHoursArray_ForDay,
} from "ChartsData.js";

const colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  dark: "#99bad8",
  blue: "#c6d9e6",
  purple: "#c4b8e0",
  pink: "#e9b9c8",
  green: "#a6c090",
  orange: "#e7d3a7"
}

const options = {
  scales: {
    responsive: true,
    yAxes: [
      {
        ticks: {
          responsive: true
        }
      },
    ],
  }
}

const options2 = {
  rotation: 1 * Math.PI,
  circumference: 1 * Math.PI
}

const options3 = {
  scales: {
    responsive: true,
    yAxes: [
      {
        ticks: {
          responsive: true
        }
      },
    ],
  },
  legend: {
    display: true,
    position: "top",
    labels: {
      usePointStyle: true,
      padding: 10,
    },
  }
}

var DH = {
  labels: ["Relative Humidity", ""],
  datasets: [
    {
      label: "Relative Humidity",
      data: [0, 100],
      backgroundColor: [
        colors.blue,
        'rgb(255, 255, 255)'
      ],
      borderColor: colors.gray[600],
    }
  ]
}

var DT = {
  labels: ["Temperature", ""],
  datasets: [
    {
      label: "Temperature",
      data: [0, 55],
      backgroundColor: [
        colors.blue,
        'rgb(255, 255, 255)'
      ],
      borderColor: colors.gray[600],
    }
  ]
}

var DCO2 = {
  labels: ["CO2", ""],
  datasets: [
    {
      label: "CO2",
      data: [0, 10000],
      backgroundColor: [
        colors.blue,
        'rgb(255, 255, 255)'
      ],
      borderColor: colors.gray[600],
    }
  ]
}

var DTVOC = {
  labels: ["TVOC", ""],
  datasets: [
    {
      label: "TVOC",
      data: [0, 10000],
      backgroundColor: [
        colors.blue,
        'rgb(255, 255, 255)'
      ],
      borderColor: colors.gray[600],
    }
  ]
}

var DPM1 = {
  labels: ["PM1", ""],
  datasets: [
    {
      label: "PM1",
      data: [0, 1000],
      backgroundColor: [
        colors.blue,
        'rgb(255, 255, 255)'
      ],
      borderColor: colors.gray[600],
    }
  ]
}

var DPM2_5 = {
  labels: ["PM2.5", ""],
  datasets: [
    {
      label: "PM2.5",
      data: [0, 1000],
      backgroundColor: [
        colors.blue,
        'rgb(255, 255, 255)'
      ],
      borderColor: colors.gray[600],
    }
  ]
}

var DPM4 = {
  labels: ["PM4", ""],
  datasets: [
    {
      label: "PM4",
      data: [0, 1000],
      backgroundColor: [
        colors.blue,
        'rgb(255, 255, 255)'
      ],
      borderColor: colors.gray[600],
    }
  ]
}

var DPM10 = {
  labels: ["PM10", ""],
  datasets: [
    {
      label: "PM10",
      data: [0, 1000],
      backgroundColor: [
        colors.blue,
        'rgb(255, 255, 255)'
      ],
      borderColor: colors.gray[600],
    }
  ]
}

var T = {
  labels: initLabels(1),
  datasets: [
    {
      label: "Temperature",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.blue,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.blue,
      fill: false,
      tension: 0.1
    }
  ]
}

var H = {
  labels: initLabels(1),
  datasets: [
    {
      label: "Relative Humidity",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.blue,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.blue,
      fill: false,
      tension: 0.1
    }
  ]
}

var CO2 = {
  labels: initLabels(1),
  datasets: [
    {
      label: "CO2",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.blue,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.blue,
      fill: false,
      tension: 0.1
    }
  ]
}

var TVOC = {
  labels: initLabels(1),
  datasets: [
    {
      label: "TVOC",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.blue,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.blue,
      fill: false,
      tension: 0.1
    }
  ]
}

var PM = {
  labels: initLabels(1),
  datasets: [
    {
      label: "PM1",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.blue,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.blue,
      fill: false,
      tension: 0.1
    },
    {
      label: "PM2.5",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.green,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.green,
      fill: false,
      tension: 0.1
    },
    {
      label: "PM4",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.purple,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.purple,
      fill: false,
      tension: 0.1
    },
    {
      label: "PM10",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.pink,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.pink,
      fill: false,
      tension: 0.1
    }
  ]
}

var NC = {
  labels: initLabels(1),
  datasets: [
    {
      label: "PM0.5",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.orange,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.orange,
      fill: false,
      tension: 0.1
    },
    {
      label: "PM1",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.blue,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.blue,
      fill: false,
      tension: 0.1
    },
    {
      label: "PM2.5",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.green,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.green,
      fill: false,
      tension: 0.1
    },
    {
      label: "PM4",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.purple,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.purple,
      fill: false,
      tension: 0.1
    },
    {
      label: "PM10",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: colors.pink,
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: colors.pink,
      fill: false,
      tension: 0.1
    }
  ]
}

function initLabels(n) {
  if (n === 6 || n === 12) {
    var m = 15;
    n === 6 ? m = 15 : m = 30;
    let TwoTimeObj = getTwoTimeObj_ForHour(m, n);
    return getLabels(TwoTimeObj, n);
  }
  else {
    let TwoTimeObj = getTwoTimeObj_ForDay(n);
    return getLabels(TwoTimeObj, n);
  }
}

function getLabels(TwoTimeObj, n) {
  if (n === 6 || n === 12) {
    var m = 15;
    n === 6 ? m = 15 : m = 30;
    let label = getHoursArray_ForHour(TwoTimeObj[0], m);
    return label;
  }
  else {
    let label = getHoursArray_ForDay(TwoTimeObj[0], n);
    return label;
  }
}

function dateToString(timestamp) {
  if (typeof (timestamp) === 'string') {
    const date = new Date(timestamp);
    timestamp = date;
  }
  timestamp.setUTCHours(timestamp.getUTCHours() + 8);
  var Y = timestamp.getUTCFullYear();
  var M = timestamp.getUTCMonth() + 1;
  var D = timestamp.getUTCDate();
  var h = timestamp.getUTCHours();
  var m = timestamp.getUTCMinutes();
  var s = timestamp.getUTCSeconds();
  var dateStr = Y + "-" + p(M) + "-" + p(D) + " " + p(h) + ":" + p(m) + ":" + p(s);
  return dateStr;
}

function createDatasetArray(value, n) {
  const name = {
    6: "_6Hour", 12: "_12Hour", 1: "_1Day", 3: "_3Day", 7: "_7Day", 30: "_30Day"
  }
  var THValue = [];
  var AIRValue = [];
  var PMValue = [];
  var THLast = [];
  var AIRLast = [];
  var PMLast = [];

  var Ts = {
    labels: initLabels(1),
    datasets: [
      {
        label: "Temperature",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.blue,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.blue,
        fill: false,
        tension: 0.1
      }
    ]
  }

  var Hs = {
    labels: initLabels(1),
    datasets: [
      {
        label: "Relative Humidity",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.blue,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.blue,
        fill: false,
        tension: 0.1
      }
    ]
  }

  var CO2s = {
    labels: initLabels(1),
    datasets: [
      {
        label: "CO2",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.blue,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.blue,
        fill: false,
        tension: 0.1
      }
    ]
  }

  var TVOCs = {
    labels: initLabels(1),
    datasets: [
      {
        label: "TVOC",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.blue,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.blue,
        fill: false,
        tension: 0.1
      }
    ]
  }

  var PMs = {
    labels: initLabels(1),
    datasets: [
      {
        label: "PM1",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.blue,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.blue,
        fill: false,
        tension: 0.1
      },
      {
        label: "PM2.5",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.green,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.green,
        fill: false,
        tension: 0.1
      },
      {
        label: "PM4",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.purple,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.purple,
        fill: false,
        tension: 0.1
      },
      {
        label: "PM10",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.pink,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.pink,
        fill: false,
        tension: 0.1
      }
    ]
  }

  var NCs = {
    labels: initLabels(1),
    datasets: [
      {
        label: "PM0.5",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.orange,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.orange,
        fill: false,
        tension: 0.1
      },
      {
        label: "PM1",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.blue,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.blue,
        fill: false,
        tension: 0.1
      },
      {
        label: "PM2.5",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.green,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.green,
        fill: false,
        tension: 0.1
      },
      {
        label: "PM4",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.purple,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.purple,
        fill: false,
        tension: 0.1
      },
      {
        label: "PM10",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors.pink,
        pointStyle: "circle",
        pointRadius: 4,
        pointBackgroundColor: colors.pink,
        fill: false,
        tension: 0.1
      }
    ]
  }

  var DTs = Object.assign({}, DT);
  var DHs = Object.assign({}, DH);
  var DCO2s = Object.assign({}, DCO2);
  var DTVOCs = Object.assign({}, DTVOC);
  var DPM1s = Object.assign({}, DPM1);
  var DPM2_5s = Object.assign({}, DPM2_5);
  var DPM4s = Object.assign({}, DPM4);
  var DPM10s = Object.assign({}, DPM10);

  if (typeof (value[name[n]]) !== "string") {
    THValue = value[name[n]][0];
    if (n === 1)
      THValue[3][2] = dateToString(THValue[3][2]);
  }
  if (typeof (value[name[n]]) !== "string") {
    AIRValue = value[name[n]][1];
    if (n === 1)
      AIRValue[3][2] = dateToString(AIRValue[3][2]);
  }
  if (typeof (value[name[n]]) !== "string") {
    PMValue = value[name[n]][2];
    if (n === 1)
      PMValue[10][9] = dateToString(PMValue[10][9]);
  }

  if (THValue !== []) {
    let TimeArray = THValue[0];
    Ts.labels = getLabels(TimeArray, n);

    let Array = THValue[1];
    Ts.datasets[0].data = Array;

    let Array2 = THValue[2];
    Hs.labels = Ts.labels;
    Hs.datasets[0].data = Array2;
    if (n === 1) {
      let LastArray = THValue[3];
      let LastT = LastArray[0];
      DTs.datasets[0].data = [LastT, 55 - LastT];
      DTs.datasets[0].backgroundColor[0] = LastT < 20 ? colors.blue : LastT > 30 ? colors.pink : colors.green;
      let LastH = LastArray[1];
      DHs.datasets[0].data = [LastH, 100 - LastH];
      DHs.datasets[0].backgroundColor[0] = LastH < 45 ? colors.blue : LastH > 65 ? colors.pink : colors.green;
      THLast = [LastArray[0], LastArray[1], LastArray[2]];
    }
  }

  if (AIRValue !== []) {
    let TimeArray = AIRValue[0];
    CO2s.labels = getLabels(TimeArray, n);

    let Array = AIRValue[1];
    CO2s.datasets[0].data = Array;

    let Array2 = AIRValue[2];
    TVOCs.labels = CO2s.labels;
    TVOCs.datasets[0].data = Array2;
    if (n === 1) {
      let LastArray = AIRValue[3];
      let LastCO2 = LastArray[0];
      DCO2s.datasets[0].data = LastCO2 < 10000 ? [LastCO2, 10000 - LastCO2] : [LastCO2, 0];
      DCO2s.datasets[0].backgroundColor[0] = LastCO2 < 5000 ? colors.blue : colors.pink;
      let LastTVOC = LastArray[1];
      DTVOCs.datasets[0].data = LastTVOC < 10000 ? [LastTVOC, 10000 - LastTVOC] : [LastTVOC, 0];
      DTVOCs.datasets[0].backgroundColor[0] = LastTVOC < 500 ? colors.blue : colors.pink
      AIRLast = [LastArray[0], LastArray[1], LastArray[2]];
    }
  }

  if (PMValue !== []) {
    let PM1 = PMValue[1];
    PMs.labels = getLabels(PMValue[0], n);
    PMs.datasets[0].data = PM1;
    let PM2_5 = PMValue[2];
    PMs.datasets[1].data = PM2_5;
    let PM4 = PMValue[3];
    PMs.datasets[2].data = PM4;
    let PM10 = PMValue[4];
    PMs.datasets[3].data = PM10;

    let NC0_5 = PMValue[5];
    NCs.labels = PMs.labels;
    NCs.datasets[0].data = NC0_5;
    let NC1 = PMValue[6];
    NCs.datasets[1].data = NC1;
    let NC2_5 = PMValue[7];
    NCs.datasets[2].data = NC2_5;
    let NC4 = PMValue[8];
    NCs.datasets[3].data = NC4;
    let NC10 = PMValue[9];
    NCs.datasets[4].data = NC10;
    if (n === 1) {
      let LastPM1 = PMValue[10][0];
      DPM1s.datasets[0].data = [LastPM1, 100 - LastPM1];
      let LastPM2_5 = PMValue[10][1];
      DPM2_5s.datasets[0].data = [LastPM2_5, 100 - LastPM2_5];
      let LastPM4 = PMValue[10][2];
      DPM4s.datasets[0].data = [LastPM4, 100 - LastPM4];
      let LastPM10 = PMValue[10][3];
      DPM10s.datasets[0].data = [LastPM10, 100 - LastPM10];
      PMLast = [LastPM1, LastPM2_5, LastPM4, LastPM10, PMValue[10][9]];
    }
  }

  var DatasetArray = [];
  if (n === 1) {
    DatasetArray = [Ts, Hs, CO2s, TVOCs, PMs, NCs, DT, DH, DCO2, DTVOC, DPM1, DPM2_5, DPM4, DPM10, THLast, AIRLast, PMLast];
  }
  else {
    DatasetArray = [Ts, Hs, CO2s, TVOCs, PMs, NCs];
  }
  return DatasetArray;
}

class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DTs: DT,
      DHs: DH,
      DCO2s: DCO2,
      DTVOCs: DTVOC,
      DPM1s: DPM1,
      DPM2_5s: DPM2_5,
      DPM4s: DPM4,
      DPM10s: DPM10,
      Ts: { _6Hour: T, _12Hour: T, _1Day: T, _3Day: T, _7Day: T, _30Day: T },
      Hs: { _6Hour: H, _12Hour: H, _1Day: H, _3Day: H, _7Day: H, _30Day: H },
      CO2s: { _6Hour: CO2, _12Hour: CO2, _1Day: CO2, _3Day: CO2, _7Day: CO2, _30Day: CO2 },
      TVOCs: { _6Hour: TVOC, _12Hour: TVOC, _1Day: TVOC, _3Day: TVOC, _7Day: TVOC, _30Day: TVOC },
      PMs: { _6Hour: PM, _12Hour: PM, _1Day: PM, _3Day: PM, _7Day: PM, _30Day: PM },
      NCs: { _6Hour: NC, _12Hour: NC, _1Day: NC, _3Day: NC, _7Day: NC, _30Day: NC },
      THLoad: [],
      THLast: [0, 0, dateToString(new Date())],
      AIRLast: [0, 0, dateToString(new Date())],
      PMLast: [0, 0, 0, 0, dateToString(new Date())],
      activeKey: 1,
      chartName: "_1Day",
      titleStr: "24 Hours"
    }
    this.Change = this.Change.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  async loadData() {
    try {
      const res = await fetch("https://a.tiot.tw/api/GetAQIEntity");

      let data = await res.json();
      this.setState({
        THLoad: data._1Day[0][3]
      })
    } catch (err) {
      console.error(err);
    }
  }

  async Change() {
    var value = await getData();
    var _1Day = createDatasetArray(value, 1);
    var _6Hour = createDatasetArray(value, 6);
    var _12Hour = createDatasetArray(value, 12);
    var _3Day = createDatasetArray(value, 3);
    var _7Day = createDatasetArray(value, 7);
    var _30Day = createDatasetArray(value, 30);

    var Ts = {};
    Ts._1Day = _1Day[0];
    Ts._6Hour = _6Hour[0];
    Ts._12Hour = _12Hour[0];
    Ts._3Day = _3Day[0];
    Ts._7Day = _7Day[0];
    Ts._30Day = _30Day[0];

    var Hs = {};
    Hs._1Day = _1Day[1];
    Hs._6Hour = _6Hour[1];
    Hs._12Hour = _12Hour[1];
    Hs._3Day = _3Day[1];
    Hs._7Day = _7Day[1];
    Hs._30Day = _30Day[1];

    var CO2s = {};
    CO2s._1Day = _1Day[2];
    CO2s._6Hour = _6Hour[2];
    CO2s._12Hour = _12Hour[2];
    CO2s._3Day = _3Day[2];
    CO2s._7Day = _7Day[2];
    CO2s._30Day = _30Day[2];

    var TVOCs = {};
    TVOCs._1Day = _1Day[3];
    TVOCs._6Hour = _6Hour[3];
    TVOCs._12Hour = _12Hour[3];
    TVOCs._3Day = _3Day[3];
    TVOCs._7Day = _7Day[3];
    TVOCs._30Day = _30Day[3];

    var PMs = {};
    PMs._1Day = _1Day[4];
    PMs._6Hour = _6Hour[4];
    PMs._12Hour = _12Hour[4];
    PMs._3Day = _3Day[4];
    PMs._7Day = _7Day[4];
    PMs._30Day = _30Day[4];

    var NCs = {};
    NCs._1Day = _1Day[5];
    NCs._6Hour = _6Hour[5];
    NCs._12Hour = _12Hour[5];
    NCs._3Day = _3Day[5];
    NCs._7Day = _7Day[5];
    NCs._30Day = _30Day[5];

    var Nows = {};
    Nows.DT = _1Day[6];
    Nows.DH = _1Day[7];
    Nows.DCO2 = _1Day[8];
    Nows.DTVOC = _1Day[9];
    Nows.DPM1 = _1Day[10];
    Nows.DPM2_5 = _1Day[11];
    Nows.DPM4 = _1Day[12];
    Nows.DPM10 = _1Day[13];
    Nows.THLast = _1Day[14];
    Nows.AIRLast = _1Day[15];
    Nows.PMLast = _1Day[16];
    this.setState(state => {
      return {
        DTs: Nows.DT,
        DHs: Nows.DH,
        DCO2s: Nows.DCO2,
        DTVOCs: Nows.DTVOC,
        DPM1s: Nows.DPM1,
        DPM2_5s: Nows.DPM2_5,
        DPM4s: Nows.DPM4,
        DPM10s: Nows.DPM10,
        Ts: Ts,
        Hs: Hs,
        CO2s: CO2s,
        TVOCs: TVOCs,
        PMs: PMs,
        NCs: NCs,
        THLast: Nows.THLast,
        AIRLast: Nows.AIRLast,
        PMLast: Nows.PMLast,
      }
    });
  }

  componentDidMount() {
    this.loadData();
    this.interval = setInterval(this.loadData, 60000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.THLoad) !== JSON.stringify(this.state.THLoad)) {
      this.Change();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleSelect(event, selectedKey) {
    const name = {
      6: "_6Hour", 12: "_12Hour", 1: "_1Day", 3: "_3Day", 7: "_7Day", 30: "_30Day"
    }
    const title = {
      6: "6 hours", 12: "12 hours", 1: "24 hours", 3: "3 days", 7: "7 days", 30: "30 days"
    }
    this.setState({ activeKey: selectedKey, chartName: name[selectedKey], titleStr: title[selectedKey] });
  }

  render() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }

    return (
      <>
        <SimpleHeader name="Air Quality Monitor" />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="3">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>Temperature at {this.state.THLast[2]}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Doughnut
                      data={this.state.DTs}
                      options={options2}
                      id="Doughnut-T"
                      className="chart-canvas"
                    />
                  </div>
                  <div style={{ fontSize: '200%', color: colors.gray[600], display: 'flex', justifyContent: 'center' }}>
                    {this.state.THLast[0]} °C
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>Relative Humidity at {this.state.THLast[2]}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Doughnut
                      data={this.state.DHs}
                      options={options2}
                      id="Doughnut-H"
                      className="chart-canvas"
                    />
                  </div>
                  <div style={{ fontSize: '200%', color: colors.gray[600], display: 'flex', justifyContent: 'center' }}>
                    {this.state.THLast[1]} %RH
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>CO2 at {this.state.AIRLast[2]}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Doughnut
                      data={this.state.DCO2s}
                      options={options2}
                      id="Doughnut-CO2"
                      className="chart-canvas"
                    />
                  </div>
                  <div style={{ fontSize: '200%', color: colors.gray[600], display: 'flex', justifyContent: 'center' }}>
                    {this.state.AIRLast[0]} ppm
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>TVOC at {this.state.AIRLast[2]}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Doughnut
                      data={this.state.DTVOCs}
                      options={options2}
                      id="Doughnut-TVOC"
                      className="chart-canvas"
                    />
                  </div>
                  <div style={{ fontSize: '200%', color: colors.gray[600], display: 'flex', justifyContent: 'center' }}>
                    {this.state.AIRLast[1]} ppb
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="3">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>PM1 at {this.state.PMLast[4]}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Doughnut
                      data={this.state.DPM1s}
                      options={options2}
                      id="Doughnut-PM1"
                      className="chart-canvas"
                    />
                  </div>
                  <div style={{ fontSize: '200%', color: colors.gray[600], display: 'flex', justifyContent: 'center' }}>
                    {this.state.PMLast[0]} µg/m³
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>PM2.5 at {this.state.PMLast[4]}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Doughnut
                      data={this.state.DPM2_5s}
                      options={options2}
                      id="Doughnut-PM2_5"
                      className="chart-canvas"
                    />
                  </div>
                  <div style={{ fontSize: '200%', color: colors.gray[600], display: 'flex', justifyContent: 'center' }}>
                    {this.state.PMLast[1]} µg/m³
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>PM4 at {this.state.PMLast[4]}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Doughnut
                      data={this.state.DPM4s}
                      options={options2}
                      id="Doughnut-PM4"
                      className="chart-canvas"
                    />
                  </div>
                  <div style={{ fontSize: '200%', color: colors.gray[600], display: 'flex', justifyContent: 'center' }}>
                    {this.state.PMLast[2]} µg/m³
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>PM10 at {this.state.PMLast[4]}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Doughnut
                      data={this.state.DPM10s}
                      options={options2}
                      id="Doughnut-PM10"
                      className="chart-canvas"
                    />
                  </div>
                  <div style={{ fontSize: '200%', color: colors.gray[600], display: 'flex', justifyContent: 'center' }}>
                    {this.state.PMLast[3]} µg/m³
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Nav pills>
                    <NavItem>
                      <NavLink className={"py-2 px-3"} href="#6Hours" onClick={(e) => this.handleSelect(e, 6)} >
                        <span className="d-none d-md-block">6 Hours</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={"py-2 px-3"} href="#12Hours" onClick={(e) => this.handleSelect(e, 12)} >
                        <span className="d-none d-md-block">12 Hours</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="mr-2 mr-md-0">
                      <NavLink className={"py-2 px-3"} href="#1Day" onClick={(e) => this.handleSelect(e, 1)} >
                        <span className="d-none d-md-block">1 Day</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="mr-2 mr-md-0">
                      <NavLink className={"py-2 px-3"} href="#3Day" onClick={(e) => this.handleSelect(e, 3)} >
                        <span className="d-none d-md-block">3 Day</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="mr-2 mr-md-0">
                      <NavLink className={"py-2 px-3"} href="#7Day" onClick={(e) => this.handleSelect(e, 7)} >
                        <span className="d-none d-md-block">7 Day</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="mr-2 mr-md-0">
                      <NavLink className={"py-2 px-3"} href="#30Day" onClick={(e) => this.handleSelect(e, 30)} >
                        <span className="d-none d-md-block">30 Day</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>Temperature for the past {this.state.titleStr}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={this.state.Ts[this.state.chartName]}
                      options={options}
                      id="chart-T"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>Relative Humidity for the past {this.state.titleStr}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={this.state.Hs[this.state.chartName]}
                      options={options}
                      id="chart-H"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>CO2 for the past {this.state.titleStr}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={this.state.CO2s[this.state.chartName]}
                      options={options}
                      id="chart-CO2"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>TVOC for the past {this.state.titleStr}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={this.state.TVOCs[this.state.chartName]}
                      options={options}
                      id="chart-TVOC"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>Mass Concentration for the past {this.state.titleStr}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={this.state.PMs[this.state.chartName]}
                      options={options3}
                      id="chart-PM"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardHeader>
                  <h5 className="h2 mb-0" style={{ display: 'flex', justifyContent: 'center' }}>Number Concentration for the past {this.state.titleStr}</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={this.state.NCs[this.state.chartName]}
                      options={options3}
                      id="chart-NC"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Charts;