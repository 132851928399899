var fetch = require("node-fetch");

export function p(n) { // padding
    return n.toString().padStart(2, "0");
}

export function getTwoTimeObj_ForHour(n, n2) { // 6 & 12 hours // n = A few minutes apart, n2 = how many hours
    var date = new Date();
    date.setUTCMinutes(n * (Math.floor(date.getUTCMinutes() / n) + 1));
    date.setUTCSeconds(59);
    date.setUTCMilliseconds(999);
    var EarlierTime = new Date(date.toUTCString());
    EarlierTime.setUTCHours(date.getUTCHours() - n2);
    EarlierTime.setUTCSeconds(0);
    EarlierTime.setUTCMilliseconds(0);
    var obj = [];
    obj[0] = EarlierTime.toUTCString();
    obj[1] = date.toUTCString();
    return obj;
}

export function getTwoTimeObj_ForDay(n) { // 1 & 3 & 7 & 30 days // n = how many days
    var date = new Date();
    date.setUTCMinutes(59);
    date.setUTCSeconds(59);
    date.setUTCMilliseconds(999);
    var EarlierTime = new Date(date.toUTCString());
    EarlierTime.setUTCHours(date.getUTCHours() - (n * 24 - 1));
    EarlierTime.setUTCMinutes(0);
    EarlierTime.setUTCSeconds(0);
    EarlierTime.setUTCMilliseconds(0);
    var obj = [];
    obj[0] = EarlierTime.toUTCString();
    obj[1] = date.toUTCString();
    return obj;
}

export function getHoursArray_ForHour(EarlierTime, n) { // 6 & 12 hours // n = A few minutes apart
    var d = new Date(EarlierTime);
    d.setUTCHours(d.getUTCHours() + 8);
    var Y = d.getUTCFullYear(),
        M = d.getUTCMonth() + 1,
        day = d.getUTCDate(),
        h = d.getUTCHours(),
        m = d.getUTCMinutes(),
        stamp = Y + "-" + p(M) + "-" + p(day) + " " + p(h) + ":" + p(m);

    var arr = [];
    var tmp = d.getTime();
    var NowDay = day;
    for (let i = 0; i < 24; i++) {
        if (i === 0) { arr.push(stamp); }
        else {
            var tmpD = new Date(tmp + n * 60 * 1000),
                tmpY = tmpD.getUTCFullYear(),
                tmpM = tmpD.getUTCMonth() + 1,
                tmpDay = tmpD.getUTCDate(),
                tmpH = tmpD.getUTCHours(),
                tmp_m = tmpD.getUTCMinutes()
            tmp = tmpD.getTime();
            if (NowDay !== tmpDay) {
                arr.push(tmpY + "-" + p(tmpM) + "-" + p(tmpDay) + " " + p(tmpH) + ":" + p(tmp_m));
                NowDay = tmpDay;
            }
            else {
                arr.push(p(tmpH) + ":" + p(tmp_m));
            }
        }
    }

    return arr;
}

export function getHoursArray_ForDay(EarlierTime, n) { // 1 & 3 & 7 & 30 days // n = A few hours apart
    var d = new Date(EarlierTime);
    d.setUTCHours(d.getUTCHours() + 8);
    var Y = d.getUTCFullYear(),
        M = d.getUTCMonth() + 1,
        day = d.getUTCDate(),
        h = d.getUTCHours(),
        stamp = Y + "-" + p(M) + "-" + p(day) + " " + p(h) + ":00";

    var arr = [];
    var tmp = d.getTime();
    var NowDay = day;
    for (let i = 0; i < 24; i++) {
        if (i === 0) { arr.push(stamp); }
        else {
            var tmpD = new Date(tmp + n * 60 * 60 * 1000),
                tmpY = tmpD.getUTCFullYear(),
                tmpM = tmpD.getUTCMonth() + 1,
                tmpDay = tmpD.getUTCDate(),
                tmpH = tmpD.getUTCHours();
            tmp = tmpD.getTime();
            if (NowDay !== tmpDay) {
                arr.push(tmpY + "-" + p(tmpM) + "-" + p(tmpDay) + " " + p(tmpH) + ":00");
                NowDay = tmpDay;
            }
            else {
                arr.push(p(tmpH) + ":00");
            }
        }
    }

    return arr;
}

export async function getData() {
    try {
        const res = await fetch("https://a.tiot.tw/api/GetAQIEntity");

        if (res.status >= 400) {
            throw new Error("Bad response from server");
        }

        let data = await res.json();
        return data;
    } catch (err) {
        console.error(err);
        return 0;
    }
}